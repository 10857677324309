/* @import url('assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css');
@import url('assets/plugins/metismenu/css/metisMenu.min.css');
@import url('assets/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('assets/css/app.css');
@import url('assets/css/icons.css');
@import url('assets/css/dark-theme.css');
@import url('assets/css/semi-dark.css');
@import url('assets/css/header-colors.css'); */



@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400;700&display=swap');


/* html, body {
  height: 100%;
}  

#root {
  height: 100%;
} */

body{
  font-family: 'Noto Sans Thai', sans-serif;
  /* overflow-y: scroll !important; */
}


.modal {
  overflow-y:auto;
}

.insert {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  transform: rotate(45deg);
  opacity: .85
}

.close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 16 16' fill='red'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  opacity: .85
}



.login,
.image {
  min-height: 100vh;
}

.bg-image {
  /* background-image: url('./assets/images/bg-login.jpg'); */
  background-image: url('../../public/assets/images/bg-login.jpg');
  background-size: cover;
  background-position: center center;

}

.taps-login {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.link-insert-select{
  color:blue;
  cursor: pointer;

}


/* pagination  */

.page-item.active .page-link {
  background-color: #393436;
  border-color: #393436;
  margin-right: 2px;
}

.page-link {
  color: #393436;

  margin-right: 2px;
}

.page-link:hover {
  color: #393436;
  margin-right: 2px;
}


::-webkit-scrollbar,
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track { 
    width: 8px;
    border: none;
    background: transparent;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    display: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #949191;
}

::-webkit-scrollbar-track {
   
    background-repeat: repeat-y;
    background-size: contain;
}

/* CUSTOM STYLING HERE - IGNORE */

/* ====================== shopping-cart ==================== */
.shopping-cart-wrap img {
  max-width: 100px; }
.shopping-cart-wrap thead th {
  border-top: 0;
  border-bottom: 0; }
.shopping-cart-wrap .price {
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
  display: block; }

.cart {
  /* height: 400px;
  overflow:  auto; */
  background-color: red;
}

/* ====================== block ==================== */
.box {
  padding: 1rem 1.2rem;
  display: block;
  background: #fff;
  border-radius: 0.2rem;
  -webkit-box-shadow: 0 1px 3px rgba(51, 51, 51, 0.1);
          box-shadow: 0 1px 3px rgba(51, 51, 51, 0.1); }

/* --------- description list --------*/
[class*="dlist-"] {
  margin-bottom: 5px; }
  [class*="dlist-"] dd {
    margin-bottom: 0; }

.dlist-inline dt, .dlist-inline dd {
  display: inline-block; }

.dlist-align dt {
  width: 90px;
  float: left;
  word-wrap: break-word; }
.dlist-align dd {
  margin-left: 120px;
  vertical-align: baseline; }



  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }



  /* Payment */
  .paymenttotal {
    background-color: #F3F3F3; ;
  }
  .paymentnumber {
    background-color: #F9F9F9; cursor: pointer;
  }
  .paymentcash {
    background-color: #F9F9F9; cursor: pointer; font-weight: bold;
  }

  .paymentc {
    background-color: #F9F9F9; cursor: pointer; font-weight: bold;
  }
.paymentnumber:hover {
  background-color: #F3F3F3;
}


/* Print ใบเสร็จ */
.print-source {
  display: none;

}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;

  }
}


.auth-backgroud {

  background-color: #8EC5FC;
background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}